import {NavLink, useParams} from 'react-router-dom';
import React from 'react';
import {useCart} from '../state/cart';
import {useData} from '../state/data';

function AppMenu() {

	const { restaurant } = useParams();
	const { state: cartState } = useCart();
	const cartCount = cartState.length;

	const { state: { data } } = useData();
	const { labels } = data;

	return (
		<>
			<NavLink to={`/${restaurant}`}>
				<img src="/home-icon.svg" alt="Home" /> Home
			</NavLink>
			<NavLink to={`/${restaurant}/cart`}>
				<img src="/cart-icon.svg" alt="Cart" /> {labels.carrello} {cartCount ? <sup dangerouslySetInnerHTML={{ __html: `(${cartCount})` }} /> : ''}
			</NavLink>
		</>
	);

}

export default AppMenu;
