import * as React from 'react'
import {useEffect} from 'react';

const DataContext = React.createContext()

function dataReducer(state, action) {
	switch (action.type) {
		case 'set': {
			return {...state, data: action.data}
		}
		case 'reset': {
			return null
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

function DataProvider({children}) {

	const initialState = null;

	const [state, dispatch] = React.useReducer(dataReducer, initialState)

	const value = {state, dispatch}

	return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

function useData() {
	const context = React.useContext(DataContext)
	if (context === undefined) {
		throw new Error('useData must be used within a DataProvider')
	}
	return context
}

export {DataProvider, useData}
