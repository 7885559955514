import React from 'react';
import {useData} from '../state/data';

function Allergens() {

	const { state: { data } } = useData();
	const { labels } = data;

	return (
		<div className="Allergens">
			<div><img src="/allergens/latticini.svg" />{labels.allergeni.latticini}</div>
			<div><img src="/allergens/noci.svg" />{labels.allergeni.noci}</div>
			<div><img src="/allergens/glutine.svg" />{labels.allergeni.glutine}</div>
			<div><img src="/allergens/surgelato.svg" />{labels.allergeni.surgelato}</div>
			<div><img src="/allergens/celery.svg" />{labels.allergeni.celery}</div>
		</div>
	);

}

export default Allergens;
