import RenderMenuItem from './RenderMenuItem';

function MenuCategoryDish({items, selectedDishes, dishesNumber, toggleDish, type= 'dish', hidePrice = true, enableHideOnMenu = false }) {

	return (
		items.filter(item => !enableHideOnMenu || !item.hide_on_menu).map((item) => (
			<div className={`MenuOption ${selectedDishes.indexOf(item.id) === -1 && selectedDishes.length >= dishesNumber ? 'disabled' : ''}`} key={`dish-${item.id}`}>
				<input id={`item-${item.id}`} type="checkbox" checked={selectedDishes.indexOf(item.id) !== -1} disabled={selectedDishes.length >= dishesNumber} onChange={() => null} />
				<label htmlFor={`item-${item.id}`} onClick={() => toggleDish(item)}>
					<RenderMenuItem hidePrice={hidePrice} item={item} type={type} />
				</label>
			</div>
		))
	);

}

export default MenuCategoryDish;
