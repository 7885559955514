import React, {useEffect} from 'react';

import {Link} from 'react-router-dom';
import { useSettings } from '../state/settings';
import useWindowSize from '../windowSize';
import {useData} from '../state/data';

function SelectRestaurant() {

	const { state: settings, dispatch } = useSettings();
	const { language } = settings;
	const { dispatch: dataDispatch } = useData();

	function setLanguage(language) {

		dispatch({ type: 'setLanguage', language });

	}

	const { height } = useWindowSize();

	useEffect(() => {

		dataDispatch({
			type: 'reset'
		});
		// setLanguage('it');

	}, []);

	return (
		<div className='Main' style={{ height: `${height}px` }}>
			<div className="Main_Header Main_Header--white">
				<img src="/logo-black.svg" alt="Peace and Spice" />
				<div className="Main_HeaderRight">
					{language === 'it' ? <button onClick={() => setLanguage('en')}>EN</button> : <button onClick={() => setLanguage('it')}>IT</button>}
				</div>
			</div>
			<div className="Main_Body" style={{ textAlign: 'center' }}>
				{ language === 'it' ? (
					<p>
						Benvenuto/a da <strong>Peace and Spice</strong>,<br />
						prima di cominciare seleziona il ristorante.
					</p>
				) : (
					<p>
						Welcome to <strong>Peace and Spice</strong>,<br />
						before begin, select the restaurant
					</p>

				)}

				<Link className="CapitaniatoButton" to="/capitaniato"><div>Capitaniato<br /><span>Padova</span></div></Link>
				<Link className="PortelloButton" to="/portello"><div>Portello,<br /><span>Padova</span></div></Link>
				<Link className="VeneziaButton" to="/venezia"><div>Querini<br /><span>Venezia</span></div></Link>
			</div>
		</div>
	);

}

export default SelectRestaurant;
