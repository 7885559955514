import React, {useEffect, useState} from 'react';
import OrderSteps from '../../components/OrderSteps';
import RenderMenuItem from '../../components/RenderMenuItem';

function Step5({ labels, onNextStep, onBackStep, menuItem, dishes, extra, beverage, selectedBeverage, selectedDishes, favWines, selectedWines, allBeverage, allWines }) {

	const [ dishesList, setDishesList ] = useState([]);

	useEffect(() => {

		const temp = selectedDishes.map(item => {
			return dishes.find(dishItem => dishItem.id === item)
		})
		setDishesList(temp);

	}, [selectedDishes]);

	return (
		<div className="OrderStep">
			<div className="OrderStep_Top">
				<h1>{labels.componi_piatto}</h1>
				<OrderSteps value={5} />
			</div>
			<div className="OrderStep_Inner">

				<p><span dangerouslySetInnerHTML={{ __html: labels.dish_preview.replace('{n}', menuItem.dishes_number)}}></span>: {selectedDishes.map(item => dishes.find(dishItem => dishItem.id === item).name).join(', ')}</p>

				{extra.length ? (
					<div>
						<p>{labels.dish_preview_extra}: {extra.map(item => dishes.find(dishItem => dishItem.id === item).name).join(', ')}</p>
					</div>
				) : null}

				{selectedBeverage.length ? (
					<div>
						<p>{labels.beverage}: {selectedBeverage.filter(item => allBeverage.find(dishItem => dishItem.id === item)).map(item => allBeverage.find(dishItem => dishItem.id === item).name).join(', ')}</p>
					</div>
				) : null}

				{selectedWines.length ? (
					<div>
						<p>{labels.wines}: {selectedWines.filter(item => allWines.find(dishItem => dishItem.id === item)).map(item => allWines.find(dishItem => dishItem.id === item).name).join(', ')}</p>
					</div>
				) : null}

			</div>
			<div className="OrderStep_Buttons">
				<button className="ButtonLink" onClick={onBackStep}>{labels.back}</button>
				<button className="Button" type="button" onClick={onNextStep}>{labels.aggiungi_al_carrello}</button>
			</div>
		</div>
	);

}

export default Step5;
