import React, {useContext} from 'react';
import {MenuContext} from '../contexts';
import RenderMenuItem from '../components/RenderMenuItem';
import {useData} from '../state/data';
import {useParams} from 'react-router-dom';

function Beverage() {

	const { category } = useParams();
	const { state: { data } } = useData();
	const { labels, beverage, allBeverage } = data;

	const items = beverage[category];
	const categories = Object.keys(items);

	return (
		<div>
			{categories.map(categoryItem => (
				<div key={`${categoryItem}`}>
					<h2>{allBeverage.find(item => items[categoryItem][0] === item.id).category}</h2>
					{allBeverage.filter(item => items[categoryItem].indexOf(item.id) !== -1).map(item => <RenderMenuItem item={item} type="beverage" key={`beverage-${item.id}`} />)}
				</div>
			))}

		</div>
	);

}

export default Beverage;
