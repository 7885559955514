import it from 'date-fns/locale/it';
import {format} from 'date-fns';

export const currency = (price, showSymbol = true) => (showSymbol ? '€ ' : '') + `${price ? price.replace('.', ',') : ''}`;

export const getCart = () => {
	const cart = localStorage.getItem('cart');
	if (cart)
		return JSON.parse(cart);
	return [];
}

export const addItemToCart = (object) => {

	let cart = getCart();
	cart.push(object);
	localStorage.setItem('cart', JSON.stringify(cart))
	return cart;

}

export const removeItemFromCart = (index) => {

	let cart = getCart() && [];
	if (cart.length > index) {
		cart = cart.filter((item, itemIndex) => itemIndex !== index);
	}
	localStorage.setItem('cart', JSON.stringify(cart))
	return cart;

}

export const getNow = () => {

	return format(new Date(), 'd/L/u H:mm', { locale: it })

}

export const getGlobalLanguage = () => {

	let storeLanguage = localStorage.getItem('language');
	let tempLanguage = storeLanguage ? storeLanguage : navigator.language || navigator.userLanguage;

	if (tempLanguage && tempLanguage.length)
		tempLanguage = tempLanguage.substring(0, 2);

	if (tempLanguage && tempLanguage !== 'it')
		tempLanguage = 'en';

	if (!tempLanguage)
		tempLanguage = 'it';

	return tempLanguage;

}

export const setGlobalLanguage = (language) => {

	localStorage.setItem('language', language);
	return language

}
