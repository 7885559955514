import RenderMenuItem from '../../components/RenderMenuItem';
import React, {useEffect, useState} from 'react';
import OrderSteps from '../../components/OrderSteps';
import MenuCategoryDish from '../../components/MenuCategoryDish';
import Allergens from '../../components/Allergens';
import {useData} from '../../state/data';

function Step0({ dishes, onNextStep }) {

	const { state: { data } } = useData();
	const { allDishes, labels, isLounchWorkDays } = data;


	return (
		<div className="OrderStep">
			<div className="OrderStep_Top">
				<h1>{labels.componi_piatto}</h1>
			</div>
			<div className="OrderStep_Inner">

				<div dangerouslySetInnerHTML={{ __html: labels.intro_componi_piatto }}></div>

				{!isLounchWorkDays ? <div style={{ marginBottom: 15 }}><small>{labels.coperto}</small></div> : null}

				<Allergens />

				<h2>{labels.dishCategories['riso-grano']}</h2>
				{allDishes.filter(item => dishes['riso-grano'].indexOf(item.id) !== -1).map((item) => <RenderMenuItem key={`item-${item.id}`} item={item} type="dish" />)}

				<hr/>

				<h2>{labels.dishCategories['verdura']}</h2>
				{allDishes.filter(item => dishes['verdura'].indexOf(item.id) !== -1).map((item) => <RenderMenuItem key={`item-${item.id}`} item={item} type="dish" />)}

				<hr/>

				<h2>{labels.dishCategories['carne']}</h2>
				{allDishes.filter(item => dishes['carne'].indexOf(item.id) !== -1).map((item) => <RenderMenuItem key={`item-${item.id}`} item={item} type="dish" />)}

				<hr/>

				<h2>{labels.dishCategories['cicchetto']}</h2>
				{allDishes.filter(item => dishes['cicchetto'].indexOf(item.id) !== -1).map((item) => <RenderMenuItem key={`item-${item.id}`} item={item} type="dish" hidePrice={true} />)}

				<hr/>

				<h2>{labels.dishCategories['salsa']}</h2>
				{allDishes.filter(item => dishes['salsa'].indexOf(item.id) !== -1).map((item) => <RenderMenuItem key={`item-${item.id}`} item={item} type="dish" hidePrice={true} />)}

			</div>
			<div className="OrderStep_Buttons">
				<div />
				<button className="Button _full" type="button" onClick={onNextStep}>{labels.ok_cominciamo}</button>
			</div>
		</div>
	);

}

export default Step0;
