function OrderSteps({ value }) {

	return (
		<div className="OrderStep_TopBullets">
			<div className={`${value >= 1 ? 'active' :'' }`}>1</div>
			<div className={`${value >= 2 ? 'active' :'' }`}>2</div>
			<div className={`${value >= 3 ? 'active' :'' }`}>3</div>
			<div className={`${value >= 4 ? 'active' :'' }`}>4</div>
			<div className={`${value >= 5 ? 'active' :'' }`}>5</div>
		</div>
	);

}

export default OrderSteps;
