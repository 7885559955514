import * as React from 'react'
import {useEffect} from 'react';

const CartContext = React.createContext()

function cartRedoucer(state, action) {
	switch (action.type) {
		case 'add': {
			return [...state, action.item]
		}
		case 'remove': {
			return [...state.filter((item, index) => index !== action.index)]
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

function CartProvider({children}) {

	let cartItems = localStorage.getItem('cart');
	cartItems = cartItems ? JSON.parse(cartItems) : [];

	const [state, dispatch] = React.useReducer(cartRedoucer, cartItems)

	useEffect(() => {
		localStorage.setItem("cart", JSON.stringify(state));
	}, [state]);

	const value = {state, dispatch}

	return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

function useCart() {
	const context = React.useContext(CartContext)
	if (context === undefined) {
		throw new Error('useCart must be used within a CartProvider')
	}
	return context
}

export {CartProvider, useCart}
