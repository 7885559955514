import RenderMenuItem from '../../components/RenderMenuItem';
import React, {useEffect, useState} from 'react';
import Allergens from '../../components/Allergens';
import MenuCategoryDish from '../../components/MenuCategoryDish';
import OrderSteps from '../../components/OrderSteps';
import {useData} from '../../state/data';


function Step2({ selectedMenu, dishesNumber, onBackStep , onNextStep, selectedDishes, setSelectedDishes }) {

	const { state: { data } } = useData();
	const { dishes, labels, allDishes } = data;

	function toggleDish(dish) {

		if (selectedDishes.indexOf(dish.id) !== -1) {
			setSelectedDishes([...selectedDishes.filter(item => item !== dish.id)]);
		} else if(selectedDishes.length < dishesNumber) {
			setSelectedDishes([...selectedDishes, dish.id]);
		}

	}

	return (
		<div className="OrderStep">
			<div className="OrderStep_Top">
				<h1>{labels.componi_piatto}</h1>
				<OrderSteps value={2} />
			</div>
			<div className="OrderStep_Inner">

				<p>Hai scelto il menu con {dishesNumber} pietanze: { selectedMenu.description.toLowerCase() }.</p>

				<Allergens />

				<h2>{labels.dishCategories['riso-grano']}</h2>
				<MenuCategoryDish items={allDishes.filter(item => dishes['riso-grano'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} dishesNumber={dishesNumber} toggleDish={toggleDish} />

				<h2>{labels.dishCategories['verdura']}</h2>
				<MenuCategoryDish items={allDishes.filter(item => dishes['verdura'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} dishesNumber={dishesNumber} toggleDish={toggleDish} />

				<h2>{labels.dishCategories['carne']}</h2>
				<MenuCategoryDish items={allDishes.filter(item => dishes['carne'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} dishesNumber={dishesNumber} toggleDish={toggleDish} />

				{selectedMenu.extra_dishes ? (
					<>
						<h2>{labels.dishCategories['cicchetto']}</h2>
						<MenuCategoryDish items={allDishes.filter(item => dishes['cicchetto'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} dishesNumber={dishesNumber} toggleDish={toggleDish} />

						<h2>{labels.dishCategories['salsa']}</h2>
						<MenuCategoryDish items={allDishes.filter(item => dishes['salsa'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} dishesNumber={dishesNumber} toggleDish={toggleDish} />
					</>
				) : null}

			</div>
			<div className="OrderStep_Buttons">
				<button className="ButtonLink" onClick={onBackStep}>{labels.back}</button>
				<div className="OrderStep_ButtonsText">
					{selectedDishes.length === 1 ? labels.order_dish_count.replace('{t}', dishesNumber) : labels.order_dish_count_multiple.replace('{s}', selectedDishes.length).replace('{t}', dishesNumber)}
				</div>
				<button className="Button" type="button" disabled={selectedDishes.length < dishesNumber} onClick={onNextStep}>{labels.next}</button>
			</div>

		</div>
	);

}

export default Step2;
