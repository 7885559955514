import RenderMenuItem from '../../components/RenderMenuItem';
import React, {useEffect, useState} from 'react';
import OrderSteps from '../../components/OrderSteps';
import MenuCategoryDish from '../../components/MenuCategoryDish';
import {useData} from '../../state/data';

function Step4({ onBackStep, handleNext, selectedDishes, setSelectedDishes, selectedWines, setSelectedWines, selectedMenu }) {

	const { state: { data } } = useData();
	const { labels, beverage, wines, allBeverage, allWines } = data;

	const bevande = beverage['bevande-calde-fredde']['bevande'];

	function toggleDish(dish) {

		if (selectedDishes.indexOf(dish.id) === -1) {
			setSelectedDishes([...selectedDishes, dish.id]);
		} else {
			setSelectedDishes([...selectedDishes.filter(item => item !== dish.id)]);
		}

	}

	function toggleWine(wine) {

		setSelectedWines(selectedWines.length ? [] : [wine.id]);

	}

	return (
		<div className="OrderStep">
			<div className="OrderStep_Top">
				<h1>{labels.componi_piatto}</h1>
				<OrderSteps value={4} />
			</div>
			<div className="OrderStep_Inner">
				<p>{labels.order_step_4}. {selectedMenu.water_inc ? labels.order_step_4_water : ''}</p>
				{bevande ? (
					<>
						<MenuCategoryDish hidePrice={false} items={allBeverage.filter(item => bevande.indexOf(item.id) !== -1)} selectedDishes={selectedDishes} toggleDish={toggleDish} />
					</>
				) : null}

				{/*true ? (
					<>
						<MenuCategoryDish hidePrice={false} items={allWines.filter(item => selectedWines.indexOf(item.id) !== -1)} selectedDishes={selectedDishes} toggleDish={toggleDish} />
					</>
				) : null*/}

			</div>
			<div className="OrderStep_Buttons">
				<button className="ButtonLink" onClick={onBackStep}>{labels.back}</button>
				<button className="Button" type="button" onClick={handleNext}>{labels.next}</button>
			</div>
		</div>
	);

}

export default Step4;
