import * as React from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from '@sentry/react';
import './App.scss';
import App from './App';
import {SettingsProvider} from './state/settings';
import {CartProvider} from './state/cart';
import {DataProvider} from './state/data';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<SettingsProvider>
		<CartProvider>
			<DataProvider>
				<App />
			</DataProvider>
		</CartProvider>
	</SettingsProvider>

)
