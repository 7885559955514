import {useContext} from 'react';
import {MenuContext} from '../contexts';
import {useData} from '../state/data';

function CartItem({ item, onDelete }) {

	const { state: { data } } = useData();
	const { menu, dishes, allDishes, labels, beverage, wines, favWines, allBeverage, allWines } = data;

	const dishesList = item.dishes
		.filter(dishId => allDishes.find(item => item.id === dishId))
		.map(dishId => allDishes.find(item => item.id === dishId).name)
		.join(', ')

	const extrasList = item.extras
		.filter(dishId => allDishes.find(item => item.id === dishId))
		.map(dishId => allDishes.find(item => item.id === dishId).name).join(', ')

	const beveragesList = item.beverage
		.filter(dishId => allBeverage.find(item => item.id === dishId))
		.map(dishId => allBeverage.find(item => item.id === dishId).name)
		// .join(', ');

	const winesList = item.wines
		.filter(dishId => allWines.find(item => item.id === dishId))
		.map(dishId => allWines.find(item => item.id === dishId).name)
		// .join(', ');

	return (
		<div className="CartItem">
			<div className="CartItem_Inner">
				<div className="CartItem_Top">
					{item.createdAt}
					<button type="button" onClick={onDelete}><img src="/icon-delete.svg" alt="Elimina" /></button>
				</div>
				<div><strong>Menu:</strong> {item.dishes.length} {labels.pietanze}</div>
				<div><strong>{labels.pietanze}:</strong> {dishesList}</div>
				{item.extras.length ? (<div><strong>{labels.cicchetti_extra}:</strong> {extrasList}</div>) : null}
				{(item.beverage && item.beverage.length) ? (
					<div>
						<strong>{labels.beverage}:</strong> {[...(item.beverage ? beveragesList : [])].join(', ')}
					</div>) : null}
			</div>

		</div>
	)

}

export default CartItem;
