import RenderMenuItem from '../../components/RenderMenuItem';
import React, {useEffect, useState} from 'react';
import MenuCategoryDish from '../../components/MenuCategoryDish';
import OrderSteps from '../../components/OrderSteps';
import {useData} from '../../state/data';

function Step3({ dishes, onBackStep, onNextStep, selectedDishes, setSelectedDishes }) {

	const { state: { data } } = useData();
	const { labels, allDishes } = data;

	function toggleDish(dish) {

		if (selectedDishes.indexOf(dish.id) === -1) {
			setSelectedDishes([...selectedDishes, dish.id]);
		} else {
			setSelectedDishes([...selectedDishes.filter(item => item !== dish.id)]);
		}

	}

	return (
		<div className="OrderStep">
			<div className="OrderStep_Top">
				<h1>{labels.componi_piatto}</h1>
				<OrderSteps value={3} />
			</div>
			<div className="OrderStep_Inner">
				<p>{labels.order_step_3}</p>

				<h2>{labels.cicchetti}</h2>
				<MenuCategoryDish hidePrice={false} items={allDishes.filter(item => dishes['cicchetto'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} toggleDish={toggleDish} />

				<h2>{labels.salse}</h2>
				<MenuCategoryDish hidePrice={false} items={allDishes.filter(item => dishes['salsa'].indexOf(item.id) !== -1)} selectedDishes={selectedDishes} toggleDish={toggleDish} />

			</div>
			<div className="OrderStep_Buttons">
				<button className="ButtonLink" onClick={onBackStep}>{labels.back}</button>
				<button className="Button" type="button" onClick={() => onNextStep()}>{labels.next}</button>
			</div>
		</div>
	);

}

export default Step3;
