import React, {useContext} from 'react';
import {MenuContext} from '../contexts';
import RenderMenuItem from '../components/RenderMenuItem';
import Allergens from '../components/Allergens';
import {useData} from '../state/data';

function Dolci() {

	const { state: { data } } = useData();
	const { dishes, labels, allDishes } = data;

	return (
		<div className="Page">
			<h1>{labels.menu_dessert}</h1>

			<Allergens />

			{allDishes.filter(item => dishes.dolce.indexOf(item.id) !== -1).map(item => <RenderMenuItem item={item} type="dish" key={`dolce-${item.id}`} />)}

		</div>
	);

}

export default Dolci;
