function Loader() {
	return (
		<div className="Loader">
			<div>
				<img src="/loader.gif" />
			</div>
		</div>
	);
}

export default Loader;
