import React, {useEffect, useState} from 'react';

import {Link, NavLink, Outlet, useParams} from 'react-router-dom';
import {MenuContext} from '../contexts';
import {getCart} from '../helpers';
import {CartProvider} from '../state/cart';
import AppMenu from '../components/AppMenu';
import useWindowSize from '../windowSize';
import {SettingsProvider, useSettings} from '../state/settings';
import {DataProvider, useData} from '../state/data';
import Loader from '../components/Loader';

function Root() {

	const { restaurant } = useParams();
	const { state: settings, dispatch } = useSettings();
	const { language } = settings;

	const [cartCount, setCartCount] = useState(0);
	const { state: data, dispatch: dataDispatch } = useData();

	const { height } = useWindowSize();

	useEffect(() => {

		async function init() {

			let url = `https://gestionale.peaceandspice.com/api/${restaurant}/menu`;

			if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
				url = `http://localhost:8000/api/${restaurant}/menu`;
			}

			// await setCurrentRestaurant(restaurant);
			let response = await fetch(url, {
				headers: {
					"Content-Type": "application/json",
					"Accept-Language": language
				},
			});
			response = await response.json();

			await dataDispatch({
				type: 'set',
				data: response
			});

		}

		init();

		const cart = getCart();
		setCartCount(cart ? cart.length : 0);

	}, [language, restaurant]);

	function setLanguage(language) {

		dispatch({ type: 'setLanguage', language });

	}

	return (
		<div className={`Main Main--${restaurant}`} style={{ height: `${height}px` }}>
			<div className="Main_Header">
				<Link to={`/${restaurant}`}><img src="/logo-white.svg" alt="Peace and Spice" /></Link>
				<div className="Main_HeaderRight">
					<Link to='/' className="Main_HeaderRestaurant">
						{restaurant === 'venezia' && <div dangerouslySetInnerHTML={{ __html: `Querini<br /><small>Venezia</small`}}></div>}
						{restaurant !== 'venezia' && <>{restaurant}<br /><small>Padova</small></>}
					</Link>
					{language === 'it' ? <button onClick={() => setLanguage('en')}>EN</button> : <button onClick={() => setLanguage('it')}>IT</button>}
				</div>
			</div>
			<div className="Main_Body">
				{!data ? <Loader /> : <Outlet />}
			</div>
			<div className="Main_Footer">
				{data ? <AppMenu /> : null}
			</div>
		</div>
	);

}

export default Root;
