import { SettingsContext } from './contexts';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import * as React from 'react';
import SelectRestaurant from './Pages/SelectRestaurant';
import Root from './Pages/Root';
import Home from './Pages/Home';
import Wines from './Pages/Wines';
import Dolci from './Pages/Dolci';
import Cicchetti from './Pages/Cicchetti';
import Beverage from './Pages/Beverage';
import ChefSuggestions from './Pages/ChefSuggestions';
import Order from './Pages/Order/Order';
import Cart from './Pages/Order/Cart';
import {SettingsProvider} from './state/settings';
import Aperitivo from './Pages/Aperitivo';

const router = createBrowserRouter([
	{
		path: "/",
		element: <SelectRestaurant />,
	},
	{
		path: ":restaurant",
		element: <Root />,
		children: [
			{
				path: "",
				element: <Home />
			},
			{
				path: "wines",
				element: <Wines />
			},
			{
				path: "dolci",
				element: <Dolci />
			},
			{
				path: "cicchetti",
				element: <Cicchetti />
			},
			{
				path: "beverage/:category",
				element: <Beverage />
			},
			{
				path: "chef-suggestions",
				element: <ChefSuggestions />
			},
			{
				path: "order",
				element: <Order/>
			},
			{
				path: "cart",
				element: <Cart />
			},
			{
				path: "aperitivo",
				element: <Aperitivo />
			},
		]
	},
	// {
	//     path: "wines",
	//     element: <Wines />,
	// },
]);
function App() {

	return (
		<SettingsProvider>
			<RouterProvider router={router} />
		</SettingsProvider>
	);

}

export default App;
