import {useContext, useEffect, useState} from 'react';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import {MenuContext} from '../../contexts';
import Step5 from './Step5';
import {addItemToCart, getCart, getNow} from '../../helpers';
import { format } from 'date-fns';
import {useParams} from 'react-router-dom';
import CartItem from '../../components/CartItem';
import {useCart} from '../../state/cart';
import {useData} from '../../state/data';

function Cart() {

	const { state: cartState, dispatch } = useCart();
	const [cart, setLocalCart] = useState([]);

	const { state: { data } } = useData();
	const { labels } = data;

	const { restaurant } = useParams();

	useEffect(() => {

		setLocalCart(getCart())

	}, []);

	function handleDeleteItem(index) {

		dispatch({ type: 'remove', index })

	}

	return (
		<div className="Cart_Container">
			<h1>{labels.carrello}</h1>
			<div className="Cart_Inner">
				{cartState.length === 0 ? <div>{labels.carrello_vuoto}</div> : null}
				{cartState.length > 0 ? cartState.map((item, index) => <CartItem key={`item-${index}`} item={item} onDelete={() => handleDeleteItem(index)} />) : null}
			</div>
			<img src="/cart-portello.svg" />
		</div>
	);

}

export default Cart;
