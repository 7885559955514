import React, {useContext} from 'react';

import {Link, useParams} from 'react-router-dom';
import {MenuContext} from '../contexts';
import {useData} from '../state/data';

function Home() {

	const { restaurant } = useParams();
	const { state: { data } } = useData();
	const { chefSuggestions, dishes, labels } = data;

	return (
		<div className="PageHome" style={{ textAlign: 'center' }}>
			<Link to="order" className="HomeButton">
				<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
				<img src={`/menu-icons-${restaurant}/menu-1.svg`} className="HomeButton_Icon" />
				<span>{labels.componi_piatto}</span>
			</Link>
			{chefSuggestions.length ? (
				<Link to="chef-suggestions" className="HomeButton">
					<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
					<img src={`/menu-icons-${restaurant}/menu-2.svg`} className="HomeButton_Icon" />
					<span>{labels.consigli_chef}</span>
				</Link>
			) : null}
			{dishes.dolce.length ? (
				<Link to="dolci" className="HomeButton">
					<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
					<img src={`/menu-icons-${restaurant}/menu-3.svg`} className="HomeButton_Icon" />
					<span>{labels.menu_dessert}</span>
				</Link>) : null}
			{dishes.cicchetto.length ? (
				<Link to="cicchetti" className="HomeButton">
					<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
					<img src={`/menu-icons-${restaurant}/menu-4.svg`} className="HomeButton_Icon" />
					<span>{labels.dishCategories.cicchetto}</span>
				</Link>) : null}
			<Link to="wines" className="HomeButton">
				<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
				<img src={`/menu-icons-${restaurant}/menu-5.svg`} className="HomeButton_Icon" />
				<span>{labels.wines}</span>
			</Link>
			<Link to="beverage/birre" className="HomeButton">
				<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
				<img src={`/menu-icons-${restaurant}/menu-6.svg`} className="HomeButton_Icon" />
				<span>{labels.beverageCategories['birre']}</span>
			</Link>
			<Link to="beverage/bevande-calde-fredde" className="HomeButton">
				<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
				<img src={`/menu-icons-${restaurant}/menu-8.svg`} className="HomeButton_Icon" />
				<span>{labels.beverageCategories['bevande-calde-fredde']}</span>
			</Link>
			<Link to="beverage/cocktails-amari" className="HomeButton">
				<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
				<img src={`/menu-icons-${restaurant}/menu-9.svg`} className="HomeButton_Icon" />
				<span>{labels.beverageCategories['cocktails-amari']}</span>
			</Link>
			<Link to="aperitivo" className="HomeButton">
				<img src="/button-placeholder.gif" className="HomeButton_Placeholder" />
				<img src={`/menu-icons-${restaurant}/menu-7.svg`} className="HomeButton_Icon" />
				<span>Aperitivo</span>
			</Link>
			<div style={{ width: '100%', textAlign: 'center'}}>
				<Link className="ButtonLink" to="/">
					{labels.change_restaurant}
				</Link>
			</div>
		</div>
	);

}

export default Home;
