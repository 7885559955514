import * as React from 'react'
import {useEffect} from 'react';

const SettingsContext = React.createContext()

function seettingsReducer(state, action) {
	switch (action.type) {
		case 'setLanguage': {
			localStorage.setItem('settings', {...state, language: action.language});
			return {...state, language: action.language}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

function SettingsProvider({children}) {

	let settings = localStorage.getItem('settings');

	let defaultLanguage = navigator.language || navigator.userLanguage.substring(0, 2);
	if (defaultLanguage !== 'it' && defaultLanguage !== 'en')
		defaultLanguage = 'it';

	settings = settings ? JSON.parse(settings) : { language: defaultLanguage };

	const [state, dispatch] = React.useReducer(seettingsReducer, settings)

	useEffect(() => {
		localStorage.setItem("settings", JSON.stringify(state));
	}, [state]);

	const value = {state, dispatch}

	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

function useSettings() {
	const context = React.useContext(SettingsContext)
	if (context === undefined) {
		throw new Error('useSettings must be used within a SettingsProvider')
	}
	return context
}

export {SettingsProvider, useSettings}
