import React from 'react';

import {NavLink, useParams} from 'react-router-dom';
import {useData} from '../state/data';
import {useSettings} from '../state/settings';

function Aperitivo() {

	const { restaurant } = useParams();
	const { state: { language } } = useSettings();
	const { state: { data } } = useData();
	const { pages } = data;

	return (
		<div>
			<h1>Aperitivo</h1>
			<div dangerouslySetInnerHTML={{ __html: pages.aperitivo }} />
		</div>
	);

}

export default Aperitivo;
