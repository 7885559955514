import React, {useContext} from 'react';
import {MenuContext} from '../contexts';
import RenderMenuItem from '../components/RenderMenuItem';
import Allergens from '../components/Allergens';
import {useData} from '../state/data';

function Cicchetti() {

	const { state: { data } } = useData();
	const { dishes, allDishes } = data;

	return (
		<div className="Page">
			<h1>Cicchetti</h1>

			<Allergens />

			{allDishes.filter(item => dishes.cicchetto.indexOf(item.id) !== -1).map(item => <RenderMenuItem item={item} type="dish" key={`cicchetto-${item.id}`} />)}
		</div>
	);

}

export default Cicchetti;
