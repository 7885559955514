import {currency} from '../helpers';
import React from 'react';


function RenderPrice({ item, type }) {
	
	if (type === 'wine') {
		return <div className="MenuItem_TopPrice">€ {item.glass_price > 0 ? currency(item.glass_price, false) + '/' : ''}{currency(item.bottle_price, false)}</div>;
	} else if (item.price) {
		return <div className="MenuItem_TopPrice">€ {currency(item.price, false)}{item.price_2 > 0 ? '/' + currency(item.price_2, false) : ''}</div>;
	}

	return '';

}

function RenderMenuItem({ item, type = 'wine', hidePrice = false }) {

	return (
		<div className="MenuItem">
			<div className="MenuItem_Top">
				<h3>
					{item.name}
					{type === 'dish' ? (
						<>
							{item.milk_derivatives ? <span className="MenuItem_Allergen"><img src="/allergens/latticini.svg" /></span>: ''}
							{item.nuts ? <span className="MenuItem_Allergen"><img src="/allergens/noci.svg" /></span>: ''}
							{item.gluten ? <span className="MenuItem_Allergen"><img src="/allergens/glutine.svg" /></span>: ''}
							{item.frozen ? <span className="MenuItem_Allergen"><img src="/allergens/surgelato.svg" />️</span> : ''}
							{item.celery ? <span className="MenuItem_Allergen"><img src="/allergens/celery.svg" />️</span> : ''}
						</>
					) : null}
				</h3>
				{!hidePrice && <RenderPrice item={item} type={type} />}
				<br />
			</div>
			{item.typology ? <i dangerouslySetInnerHTML={{ __html: item.typology }}></i> : null}
			{item.description ? (
				<p dangerouslySetInnerHTML={{ __html: item.description }} />
			) : null}


		</div>
	);

}

export default RenderMenuItem;
