import React, {useContext} from 'react';
import {MenuContext} from '../contexts';
import RenderMenuItem from '../components/RenderMenuItem';
import {useData} from '../state/data';

function Wines() {

	const { state: { data } } = useData();
	const { labels, wines, allWines } = data;

	const categories = Object.keys(wines);


	return (
		<div>
			{categories.map(categoryItem => (
				<div key={`${categoryItem}`}>
					<h2>{allWines.find(item => item.id === wines[categoryItem][0]).category}</h2>
					{allWines.filter(item => wines[categoryItem].indexOf(item.id) !== -1).map(wine => <RenderMenuItem type="wine" item={wine} key={`wine-${wine.id}`} />)}
				</div>
			))}
		</div>
	);

}

export default Wines;
