import {useContext, useEffect, useState} from 'react';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import {MenuContext} from '../../contexts';
import Step5 from './Step5';
import {addItemToCart, getCart, getNow} from '../../helpers';
import { format } from 'date-fns';
import {useNavigate, useParams} from 'react-router-dom';
import {useCart} from '../../state/cart';
import {useData} from '../../state/data';

function Order() {

	const { state: { data } } = useData();
	const { menu, dishes, allDishes, labels, beverage, favWines, allWines, allBeverage } = data;

	const { dispatch } = useCart();

	const navigate = useNavigate();
	const [ currentStep, setStep ] = useState(0);
	const [ menuId, setMenuId ] = useState(null);
	const [ dishesNumber, setDishesNumber ] = useState(null);
	const [ selectedDishes, setSelectedDishes ] = useState([]);
	const [ selectedExtras, setSelectedExtras ] = useState([]);
	const [ selectedBeverage, setSelectedBeverage ] = useState([]);
	const [ selectedWines, setSelectedWines ] = useState([]);

	const { restaurant } = useParams();

	function handleBack() {

		setStep(currentStep - 1);

	}

	function handleNext() {

		setStep(currentStep + 1);

	}

	function confirm() {

		const item = {
			createdAt: getNow(),
			dishes: selectedDishes,
			extras: selectedExtras,
			beverage: selectedBeverage,
			wines: selectedWines,
		};

		dispatch({
			type: 'add',
			item
		})

		// let url = `https://gestionale.peaceandspice.com/api/save-order`;
		//
		// if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		// 	url = `http://localhost:8000/api/save-order`;
		// }
		//
		// fetch(url, {
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// 	method: 'post',
		// 	body: JSON.stringify(item)
		// });

		navigate(`/${restaurant}/cart`);

	}

	async function handleSetMenuId(value) {

		const menuItem = menu.find(item => item.id === value);
		await setMenuId(value);
		await setDishesNumber(menuItem.dishes_number)
		if (selectedDishes.length > menuItem.dishes_number) {
			await setSelectedDishes(selectedDishes.slice(0, menuItem.dishes_number));
		}

	}

	const selectedMenu = menuId ? menu.find(item => item.id === menuId) : null;

	switch (currentStep) {
		case 0:
			return <Step0 onNextStep={handleNext} dishes={dishes} />;
		case 1:
			return <Step1 onNextStep={handleNext} onBackStep={handleBack} menuId={menuId} onSetMenuId={handleSetMenuId} />;
		case 2:
			return <Step2 onNextStep={handleNext} onBackStep={handleBack} selectedMenu={selectedMenu} selectedDishes={selectedDishes} setSelectedDishes={setSelectedDishes} dishesNumber={dishesNumber} />;
		case 3:
			return <Step3 onNextStep={handleNext} onBackStep={handleBack} dishes={dishes} selectedDishes={selectedExtras} setSelectedDishes={setSelectedExtras} />;
		case 4:
			return <Step4 labels={labels} onBackStep={handleBack} selectedMenu={selectedMenu} selectedDishes={selectedBeverage} setSelectedDishes={setSelectedBeverage} handleNext={handleNext} selectedWines={selectedWines} setSelectedWines={setSelectedWines} />;
		case 5:
			return <Step5 labels={labels} onNextStep={confirm} onBackStep={handleBack} menuItem={menu.find(item => item.id === menuId)} dishes={allDishes} allBeverage={allBeverage} allWines={allWines} selectedDishes={selectedDishes} selectedBeverage={selectedBeverage} extra={selectedExtras} selectedWines={selectedWines} />
	}

}

export default Order;
